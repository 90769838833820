@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');
:root {
  --main-text-color: #eeeeee;
  --background: #242325;
  --shadow: #00000080;
  --nav-background: #0e0d0fee;
  --nav-hover-background: #b97bff20;
  --nav-active-background: #b97bff35;
  --footer-background: #16161a;
  --error: #ff00ff;
  --navbar-height: 64px;
  --navbuttons-width: 175px;
}

body {
  background: var(--background);
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  letter-spacing: 0px;
  word-spacing: 0px;
  color: var(--main-text-color);
  font-weight: 300;
  text-decoration: none;
  font-style: normal;
  font-variant: normal;
  margin: 0;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
#imageBackground {
  background-image: url("images/space_background.png");
  height: 50vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 0px 0px 20px var(--shadow);
  position: relative;
}

#imageBackground h2 {
  font-size: 100px; 
  color: #ffffff;
  text-shadow: 0px 0px 20px var(--shadow);
}
#navbar {
  position: sticky;
  top: 0;
  width: 100%;
  left: 0;
  right: 0;
  height: var(--navbar-height);
  background: var(--nav-background);
  display: flex;
  justify-content: space-between;
  margin-top: calc(0px - var(--navbar-height));
}
#navbar img {
  height: var(--navbar-height);
  width: var(--navbar-height)
}
#gamemodes {
  display: flex;
  gap: 10vw;
  justify-content: center;
}
.gamemode {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10vh;
}
.gamemode img {
  width: 10vw;
}
.navbar-item {
  height: var(--navbar-height);
  background: var(--nav-background);
  display: flex;
  width: 175px;
  justify-content: center;
  align-items: center;
}
.navbar-item p {
  margin: 0;
  padding: 0
}
.navbar-item:hover {
  cursor: pointer;
  background-color: var(--nav-hover-background);
  color: hsl(276, 92%, 40%);;
}
.navbar-container {
  transition: all 100ms linear;
  transform: scaleY(0);
  transform-origin: top;
}
.navbar-section:hover > .navbar-container{
  transform: scaleY(1);
}

.rank {
  border: solid white 1px;
  border-radius: 10px;
  margin: 10px;
  height: fit-content;
}

.rank-head {
  padding-left: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
} 

.rank-head .arrow {
  border-color: white;
  margin-right: 40px;
  transition: all 100ms linear;
}

.rank-head .down {
  transform: scale(4) rotate(45deg);
}

.rank-head .up {
  transform: scale(4) rotate(-135deg);
}

.rank-body {
  transform-origin: top;
  transition: height 100ms linear;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}